import React from 'react'

import VectorDead from 'public/static/dead.svg'

import * as style from './styles'

const Error = ({ statusCode }) => {
  return (
    <div css={style.errorPage}>
      <VectorDead css={style.vector} />
      <h3>Well then...</h3>
      <span>
        This is embarrassing... But this page doesn&apos;t exist.
      </span>
      <span>
        Maybe you can try <a onClick={() => window.location.reload()}>refreshing the page</a>.
      </span>
    </div>
  )
}

Error.getInitialProps = ({ res, err }) => {
  let statusCode = 404

  if (res) {
    statusCode = res.statusCode
  } else if (err) {
    statusCode = err.statusCode
  }

  return { statusCode }
}

export default Error
