import { css } from '@emotion/react'

export const errorPage = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  user-select: none;

  a {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const vector = css`
  height: 10rem;
  filter: grayscale(1);
`
